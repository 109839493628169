<template>
    <div class="page-table haberTanim  mainDiv mb-100" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.sayfa.guncelleme.title")}}
        </div>
        <el-row :gutter="10" v-loading="loading || detailLoading" :element-loading-text='loading ? $t("src.views.apps.sayfa.guncelleme.updateLoading") : $t("src.views.apps.sayfa.guncelleme.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="sayfaForm" :rules="rulesSayfalar" ref="sayfaForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.baslik")' prop="baslik">:
                                            <el-input v-model="sayfaForm.baslik" v-on:blur="linkTespit()" :placeholder='$t("src.views.apps.sayfa.tanimlama.baslikPlace")'></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.altBaslik")' prop="altBaslik">:
                                            <el-input v-model="sayfaForm.altBaslik" :placeholder='$t("src.views.apps.sayfa.tanimlama.altBaslikPlace")'></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                 <el-row v-if="sayfaForm.gorunum == '1'">
                                    <el-col :lg="20" :md="20" :sm="20" :xs="20" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.guncelleme.menuBaslik")' prop="menuBaslik">:
                                            <el-input size="small"
                                            v-model="sayfaForm.menuBaslik"
                                            :placeholder='$t("src.views.apps.sayfa.guncelleme.menuBaslikPlace")'></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="4" :md="4" :sm="4" :xs="4" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.guncelleme.menuSiraNo")'
                                        prop="menuSiraNo">:
                                        <el-input size="small"
                                        v-mask="'##'"
                                        v-model="sayfaForm.menuSiraNo"
                                        :placeholder='$t("src.views.apps.sayfa.guncelleme.menuSiraNoPlace")'></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.guncelleme.menuGorunum")' prop="ustMenuID">:
                                            <!-- :disabled="!(this.sayfaForm.menuKategoriID == '1')" -->
                                            <el-select  style="width: 100%"  v-model="sayfaForm.ustMenuID">
                                                <el-option value="0" :label='$t("src.views.apps.sayfa.guncelleme.anaMenu")'></el-option>
                                                <el-option value="1" :label='$t("src.views.apps.sayfa.guncelleme.altMenu")'></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="sayfaForm.ustMenuID != '0'">
                                        <el-form-item :label='$t("src.views.apps.sayfa.guncelleme.ustMenu")' prop="ustMenuSayfaID">:
                                            <!-- :disabled="!(this.sayfaForm.menuKategoriID == '1')" -->
                                            <el-select   style="width: 100%" v-model="sayfaForm.ustMenuSayfaID">
                                                <el-option v-for="(item, index) in menuSayfaList" :key="index" :value="item.sayfaID" :label="item.baslik"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                 </el-row>


                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.icerik")' prop="icerik">:
                                            <ckeditor :editor="editor" :config="editorConfig" v-model="sayfaForm.icerik" @input="seoIcerikTespit(); $store.commit('changeTabsClose', false)"
                                            @blur="seoIcerikTespit(); $store.commit('changeTabsClose', false)"></ckeditor>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                        </div>

                        <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                            <draggable
                                data-source="juju"
                                class="list-group"
                                group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.guncelleme.resimAyar")' name="3">
                                <el-row>
                                    <Resim functionName="sayfaGuncelleImage" :imageList="imageList" :link="sayfaForm.link" :selectedsFromServer="selectedImagesFromServer"></Resim>
                                </el-row>
                            </el-collapse-item>
                            <!-- <el-collapse-item
                                class="card-shadow--medium"
                                title="Belge Yükleme"
                                name="4" >
                                <Belge functionName="sayfaTanimlaBelge" :selectedsFromServer="selectedFilesFromServer"></Belge>
                            </el-collapse-item> -->
                                   
                            </draggable>
                        </el-collapse>


                        <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                                 <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.guncelleme.seoAyar")' name="5">
                                        <el-row>
                                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.seoBaslik")'
                                                              prop="seoBaslik">:
                                                    <el-input v-on:change="$store.commit('changeTabsClose', false)"
                                                            id="seoBaslik"
                                                            v-model="sayfaForm.seoBaslik"
                                                            @input="seoBaslikChange('seoBaslik')"
                                                            :placeholder='$t("src.views.apps.sayfa.tanimlama.seoBaslikPlace")'>
                                                        <template slot="append">{{seoBaslikLimit}}</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.seoIcerik")' prop="seoIcerik">:
                                                    <el-input v-on:change="$store.commit('changeTabsClose', false)"
                                                            type="textarea"
                                                            v-on:focus="seoIcerikChange = false"
                                                            v-model="sayfaForm.seoIcerik">
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-collapse-item>
                        </el-collapse>

                </el-col>


                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse v-model="activeCollapse" class="yanForm animated fadeInUp">
                        <el-collapse-item class="card-shadow--medium" name="9" :title='$t("src.views.apps.sayfa.tanimlama.durum")'>
                            <el-row>
                                <el-button size="mini" v-on:click="sayfaGuncelle('sayfaForm')" class="onayBtn" type="primary">
                                    <label>{{$t("src.views.apps.sayfa.guncelleme.guncelle")}}</label>
                                </el-button>
                                &nbsp;
                                <el-popover
                                        placement="bottom"
                                        width="300"
                                        v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('sayfaForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                                <el-tooltip :content="$t('src.views.apps.sayfa.tanimlama.copKutusu')"
                                            :open-delay="500"
                                            placement="bottom">
                                    <el-button class="cop" v-on:click="sayfaTanimlama('sayfaForm','2')" type="text"
                                               icon="el-icon-delete"></el-button>
                                </el-tooltip>
                            </el-row>
                        </el-collapse-item>
                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.sayfatipi")' name="10">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="sayfaTuru">
                                            <!-- v-on:change="getTreeList" -->
                                            <el-select
                                                    v-model="sayfaForm.sayfaTuru"
                                                    style="width: 100%"
                                                    v-on:change="sayfaTuruChange">
                                                <el-option value="0" :label='$t("src.views.apps.sayfa.guncelleme.sayfa")'></el-option>
                                                <el-option value="1" :label='$t("src.views.apps.sayfa.guncelleme.altSayfa")'></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item
                                                v-if="visibleUstSayfa"
                                                :label='$t("src.views.apps.sayfa.tanimlama.sayfaTipi")'
                                                prop="sayfaTuru">:
                                                <el-select
                                                    size="small"
                                                    v-model="sayfaForm.secilenSayfaTuru"
                                                    style="width: 100%">
                                                    <el-option v-for="(item, index) in menuSayfaList" :value="item.sayfaID" :key="index" :label="item.baslik"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.sayfaTuruAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.gorunum")' name="11">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="gorunum">
                                            <el-switch
                                                    v-on:change="$store.commit('changeTabsClose', false)"
                                                    v-model="sayfaForm.gorunum"
                                                    active-value="1"
                                                    inactive-value="0"
                                                    active-text="Show"
                                                    inactive-text="Do not Show">
                                            </el-switch>
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.menuAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row v-if="sayfaForm.gorunum == '1'">
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item class="margin-top-10">
                                            <el-button size="mini" type="success" v-on:click="iconListDialog = true"
                                                       icon="el-icon-search">{{$t("src.views.apps.sayfa.guncelleme.iconSec")}}
                                            </el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <!-- <el-collapse-item class="card-shadow--medium"
                                              :title='$t("src.views.apps.sayfa.tanimlama.resimKonum")' name="12">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="resimKonum">
                                            <el-button type="primary" size="mini" @click="drawerResimKonum = true">
                                                {{this.btnResim}}
                                            </el-button>
                                            &nbsp;
                                            <img v-if="sayfaForm.resimKonum === '0'" style="float: right"
                                                 src="../../../assets/images/1.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.resimKonum === '1'" style="float: right"
                                                 src="../../../assets/images/2.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.resimKonum === '2'" style="float: right"
                                                 src="../../../assets/images/5.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.resimKonum === '3'" style="float: right"
                                                 src="../../../assets/images/4.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.resimKonum === '4'" style="float: right"
                                                 src="../../../assets/images/3.png" width="50%" height="50%">
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.resimKonumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                            <el-collapse-item class="card-shadow--medium"
                                              :title='$t("src.views.apps.sayfa.tanimlama.menuKonum")' name="13">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="menuKonum">
                                            <el-button type="primary" size="mini" @click="drawerMenuKonum = true">
                                                {{this.btnMenu}}
                                            </el-button>
                                            &nbsp;
                                            <img v-if="sayfaForm.menuKonum === '0'" style="float: right"
                                                 src="../../../assets/images/1.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.menuKonum === '1'" style="float: right"
                                                 src="../../../assets/images/6.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.menuKonum === '2'" style="float: right"
                                                 src="../../../assets/images/9.png" width="50%" height="50%">
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.menuKonumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                            <el-collapse-item class="card-shadow--medium"
                                              :title='$t("src.views.apps.sayfa.tanimlama.galeriKonum")' name="14">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="galeriKonum">
                                            <el-button type="primary" size="mini" @click="drawerGaleriKonum = true">
                                                {{this.btnGaleri}}
                                            </el-button>
                                            &nbsp;
                                            <img v-if="sayfaForm.galeriKonum === '0'" style="float: right"
                                                 src="../../../assets/images/1.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.galeriKonum === '1'" style="float: right"
                                                 src="../../../assets/images/7.png" width="50%" height="50%">
                                            <img v-if="sayfaForm.galeriKonum === '2'" style="float: right"
                                                 src="../../../assets/images/8.png" width="50%" height="50%">
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.galeriKonumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item> -->
                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>

            <el-dialog :title='$t("src.views.apps.sayfa.guncelleme.iconList")' :visible.sync="iconListDialog" width="60%" center>
                <div>
                    <el-row class="iconRow">
                        <el-input class="margin-bottom-10" :placeholder='$t("src.views.apps.sayfa.guncelleme.listedeOlmayanIcon")' size="medium" v-model="sayfaForm.icon"></el-input>
                        <el-col v-for="(item,index) in iconListe"
                                :key="item"
                                :label="item"
                                :value="item" :lg="4" :md="4" :sm="8" :xs="8"
                                style="border: 1px solid #9a9a9a; padding: 5px; height: 100px; text-align: center;display:table">
                            <div v-on:click="iconSelect(item)" class="iconCerceve" id="#iconCerceve">
                                <el-button type="text">
                                    <i style="font-size: 24px;text-align: center" :class="item"></i>
                                </el-button>
                                <br>
                                <span class="icon-name" :data-class="item">{{item}}</span>
                            </div>
                        </el-col>
                    </el-row>

                </div>
            </el-dialog>
            
            <!-- <el-dialog
                    title="Resim Konumu"
                    :visible.sync="drawerResimKonum"
                    width="60%">
                <el-row>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.resimKonum" label="0" @change="changeKonum('resim','0')">
                            <img src="../../../assets/images/1.png" alt="Yok" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.resimKonum" label="1" @change="changeKonum('resim','1')">
                            <img src="../../../assets/images/2.png" alt="Sağ" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.resimKonum" label="2" @change="changeKonum('resim','2')">
                            <img src="../../../assets/images/5.png" alt="Alt" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.resimKonum" label="3" @change="changeKonum('resim','3')">
                            <img src="../../../assets/images/4.png" alt="Sol" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.resimKonum" label="4" @change="changeKonum('resim','4')">
                            <img src="../../../assets/images/3.png" alt="Üst" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                </el-row>
            </el-dialog>
            <el-dialog
                    title="Menü Konumu"
                    :visible.sync="drawerMenuKonum"
                    width="50%">
                <el-row>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.menuKonum" label="0" @change="changeKonum('menu','0')">
                            <img src="../../../assets/images/1.png" alt="Yok" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.menuKonum" label="1" @change="changeKonum('menu','1')">
                            <img src="../../../assets/images/6.png" alt="Sağ" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.menuKonum" label="2" @change="changeKonum('menu','2')">
                            <img src="../../../assets/images/9.png" alt="Alt" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                </el-row>
            </el-dialog>
            <el-dialog
                    title="Galeri Konumu"
                    :visible.sync="drawerGaleriKonum"
                    width="50%">
                <el-row>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.galeriKonum" label="0" @change="changeKonum('galeri','0')">
                            <img src="../../../assets/images/1.png" alt="Yok" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.galeriKonum" label="1" @change="changeKonum('galeri','1')">
                            <img src="../../../assets/images/7.png" alt="Dikey,Büyük" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="sayfaForm.galeriKonum" label="2" @change="changeKonum('galeri','2')">
                            <img src="../../../assets/images/8.png" alt="Yatay,Küçük" width="80%">
                        </el-radio>
                    </el-col>
                </el-row>
            </el-dialog> -->
        </el-row>

        
    </div>
</template>

<script>
    import JQuery from 'jquery';
    let $ = JQuery;

    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import EventBus from '@/components/event-bus'
    import Icon from '@/components/IconSelect.vue'
    import Resim from '@/components/Resim.vue'
    import Belge from '@/components/Belge.vue'
    import Seo from '@/components/Seo.vue'
    import Konumlar from '@/components/Konumlar.vue'

    import iconJson from '../../../icon'
    import draggable from 'vuedraggable'
    import sayfaService from '../../../WSProvider/SayfaService'
    import menuService from '../../../WSProvider/MenuService'
    import notification from '../../../notification'
    import functions from '../../../functions'

    const he = require('he')
    export default {
        name: "SayfaGuncelle",
        components: {
            draggable,
            Icon,
            Resim,
            Belge,
            Seo,
            Konumlar
        },
        computed: {
            imageListMain(){
                return this.sayfaForm.resim
            },

            sayfaId(){
                return this.$store.getters.getSayfaData;
            },

            sayfaStoreData(){
                return this.$store.getters.getSayfaData
            }
        },
        watch: {
            imageList: function (val) {
                if (val.length === 1) {
                    this.sayfaForm.resimKonum = "1";
                    this.sayfaForm.galeriKonum = "0";
                } else if (val.length > 1) {
                    this.sayfaForm.galeriKonum = "1";
                } else {
                    this.sayfaForm.resimKonum = "0";
                    this.sayfaForm.galeriKonum = "0";
                }
            },
            sayfaStoreData(val){
                this.sayfaData = this.$store.getters.getSayfaData;
                this.sayfaForm.sayfaID = this.sayfaData.sayfaID;
                this.getSayfaDetay(this.sayfaData.sayfaID);
            }
        },
        data() {
            return {
                totalSayfa: 0,

                detailLoading: false,
                loading: false,
                loadingSayfaList: false,
                
                drawerResimKonum: false,
                drawerMenuKonum: false,
                drawerGaleriKonum: false,
                btnResim: "Seç",
                btnMenu: "Seç",
                btnGaleri: "Seç",

                iconListe: iconJson["v5.0.9"],
                imageList:[],
                belgeList: [],
                selectedImagesFromServer: [],
                selectedFilesFromServer: [],
                imagePath: sayfaService.imagePath,
                belgePath: sayfaService.belgePath,

                seoBaslikLimit: 0,
                iconListDialog: false,
                path: sayfaService.path,
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10','11','12','13','14', '15'],
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },
                //Tree
                visibleUstSayfa: false,
                treeList: [],

                // Form
                seoIcerikChange: true,
                visibleSil: false,
                
                menuSayfaList: [],
                sayfaListe: [],
                
                sayfaForm: {
                    baslik: "",
                    altBaslik: "",
                    icerik: "",
                    resim: [],
                    belge: [],
                    seoBaslik: '',
                    seoIcerik: '',
                    durum: "1",
                    link: '',
                    sayfaTuru: '0',
                    secilenSayfaTuru: "",
                    resimKonum: "0",
                    menuKonum: "0",
                    galeriKonum: "0",
                    // menu ile ilgil olan kısım
                    gorunum: false, // menude gorunup gorunmeyecegi
                    menuBaslik: "",
                    ustMenuID: "",
                    ustMenuSayfaID: "",
                    menuKategoriID: "",
                    menuSiraNo: "",
                    menuGorunum: '0',
                    ustMenu: '',
                },
                
                rulesSayfalar: {
                    baslik: [{
                        required: true,
                        message: "Please Enter Title",
                        trigger: 'blur'
                    }],
                    seoBaslik: [{
                        max: 70,
                        message: "You have exceeded the recommended SEO Title limit",
                        trigger: 'change'
                    }],
                    seoIcerik: [{
                       max: 160,
                        message: "You have exceeded the recommended SEO Content limit",
                        trigger: 'change'
                    }],
                },
            }
        },

        mounted() {
            this.sayfaData = this.$store.getters.getSayfaData;
            this.sayfaForm.sayfaID = this.sayfaData.sayfaID;
            this.sayfaForm.link = this.sayfaData.link;
            this.getSayfaCount();
            this.getMenuSayfalar();
            this.getSayfaDetay(this.sayfaData.sayfaID, this.sayfaData.link);

            var self = this;
            EventBus.$on("sayfaGuncelleImage", function(bool, imageArray) {
                if (bool) {
                    self.sayfaForm.resim = imageArray;
                }
            });

            EventBus.$on("sayfaGuncelleBelge", function(bool, belgeArray) {
                if (bool) {
                    self.sayfaForm.belge = belgeArray;
                }
            });            
        },
        methods: {
            getSayfaCount(){
                sayfaService.sayfaDurumSayisi(1).then(response => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.totalSayfa = response.data;
                        this.getSayfaList(1);
                    };
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.totalSayfa = 0;
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                }) 
            },
            getSayfaList(durum){
                this.loadingSayfaList = true;
                sayfaService.sayfaList(0, this.totalSayfa, durum).then(response => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token)
                        response.data.forEach(element => {
                            if(element.ustSayfaID === "0") this.sayfaListe.push(element);
                        })
                    }
                    this.loadingSayfaList = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.sayfaListe = [];
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loadingSayfaList = false;
                })
            },
            iconSelect(value) {
                this.sayfaForm.icon = value;
                $("body").find(".aktifIkon").removeClass("aktifIkon")
                $(".iconRow").find('span[data-class="' + value + '"]').closest(".iconCerceve").parent("div").addClass("aktifIkon");
                notification.Status("success", this, value + " iconu seçildi.")
            },
            getMenuSayfalar(){
                sayfaService.menuSayfalar(1).then(response => {
                    if(response.status == 200){
                        this.menuSayfaList = response.data;
                        localStorage.setItem("userDataBGSurec", response.token)
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.menuSayfaList = [];
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loadingSayfaList = false;
                })
            },
            linkTespit() {
                this.sayfaForm.seoBaslik = this.sayfaForm.baslik.substring(0, 60);     
            },
            refreshPage(){
                this.getSayfaDetay(this.sayfaData.sayfaID, this.sayfaData.link);
            },
            sayfaTuruChange(val){
                if(val === "1") this.getTreeList("1");
                else this.visibleUstSayfa = false;
            },
            getSayfaDetay(sayfaID, link) {
                this.detailLoading = true;
                this.imageList = []
                this.sayfaForm.resim = []
                sayfaService.sayfaDetay(sayfaID, link).then(response => {
                    if(response.status == 200){

                        if (response.resimler) {
                            response.resimler.forEach(element => {
                                if(element.resim != "" && element.resim){
                                    var item = {
                                        tmp_name: element.resim,
                                        name: element.resim,
                                        url: this.imagePath + element.resim,
                                    }
                                    this.imageList.push(item);
                                }
                            })
                        }
                        // if (response.data.belgeler) {
                        //     response.data.data.belgeler.forEach(element => {
                        //         if(element !== response.data.data.resim){
                        //             var item = {
                        //                 tmp_name: element.belgeAdi,
                        //                 name: element.belgeAdi,
                        //                 url: this.belgePath + element.belgeAdi,
                        //             }
                        //             this.belgeList.push(item);
                        //         }
                        //     })
                        // }
                            
                        if (response.data.link) {
                            this.sayfaForm.link = response.data.link;
                        }
                        this.sayfaForm.sayfaTuru = '0';
                        this.sayfaForm.baslik = response.data.baslik;
                        this.sayfaForm.icerik = response.data.icerik ? response.data.icerik : ""
                        this.sayfaForm.altBaslik = response.data.altBaslik;
                        this.sayfaForm.seoBaslik = response.data.seo_baslik;
                        this.sayfaForm.seoIcerik = response.data.seo_icerik;
                        this.sayfaForm.menuBaslik = response.data.menuBaslik;
                        this.sayfaForm.menuSiraNo = response.data.siraNo;
                        this.sayfaForm.menuKategoriID = response.data.menuKategoriID;
                        this.sayfaForm.icon = response.data.icon;
                        this.sayfaForm.durum = response.data.durum;
                        this.sayfaForm.gorunum = response.data.goster;
                        this.sayfaForm.resimKonum = response.data.resimKonum;
                        this.sayfaForm.menuKonum = response.data.menuKonum;
                        this.sayfaForm.galeriKonum = response.data.galeriKonum;
                        this.sayfaForm.link = response.data.link;
                        this.sayfaForm.menuGorunum = response.data.ustMenuID != '0' ? '1':'0';
                        this.sayfaForm.sayfaTuru = response.data.ustSayfaID != '0' ? '1' : '0';
                        this.visibleUstSayfa = this.sayfaForm.sayfaTuru == '1' ? true : false
                        this.sayfaForm.secilenSayfaTuru = response.data.ustSayfaID;

                        if(response.data.ustMenuID == '0'){
                            this.sayfaForm.ustMenuID = "0";
                        }else{
                            this.sayfaForm.ustMenuID = "1";
                            this.sayfaForm.ustMenuSayfaID = response.data.ustMenuID
                        }

                    }
                    this.sayfaForm = functions.removeSlashesAndDecodeStrings(this.sayfaForm); // objeyi alıp içindeki bütün valuelarda \ fazlalıklarını kaldırıp tagları decode ediyor.
                    this.detailLoading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                        this.sayfaForm = {};
                    }else{
                        //notification.Status(503, this)
                    }
                    this.detailLoading = false;
                });
                
            },
            seoIcerikTespit() {
                if (this.seoIcerikChange && this.sayfaForm.icerik) {
                    const myStr = functions.strip_html_tags(this.sayfaForm.icerik);
                    this.sayfaForm.seoIcerik = myStr.substring(0, 140);
                } else this.sayfaForm.seoIcerik = '';
            },
            getTreeList(value) {
                const self = this
                if (value === '1') {
                    sayfaService.sayfaList(0, 500, 1).then(response => {
                        if(response.status === 200){
                            localStorage.setItem("userDataBGSurec", response.token)
                            if(response.data.length > 0){
                                response.data.forEach(function(item, index){
                                    self.treeList.push({
                                        id : index,
                                        name: item.baslik,
                                        sayfaID: item.sayfaID
                                    })
                                })
                            }
                            self.visibleUstSayfa = true;
                        }
                    })
                } else {
                    self.visibleUstSayfa = false;
                }
            },
            sayfaGuncelle(formName) {
                this.$refs[formName].validate((valid,errors) => {
                    if (valid) {                       
                        var sunucudanEklenenResimler = this.selectedImagesFromServer.join(",");
                        this.$confirm("Are you sure you want to update page?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            this.loading = true;
                            var menuKategoriID = this.sayfaForm.menuKategoriID
                            var ustSayfaID = this.sayfaForm.sayfaTuru == '1' ? this.sayfaForm.secilenSayfaTuru : '0'
                            var goster = this.sayfaForm.gorunum == '1' ? '1':'0'
                            var ustMenuID = this.sayfaForm.ustMenuID == '0' ? '0': this.sayfaForm.ustMenuSayfaID
                            var menuIcon = this.sayfaForm.gorunum == '1' ? this.sayfaForm.icon : ''
                            var menuBaslik =  this.sayfaForm.gorunum == '1' ? this.sayfaForm.menuBaslik : ''
                            var menuSiraNo = this.sayfaForm.gorunum == '1' ? this.sayfaForm.menuSiraNo : ''


                            //sayfaID, ustSayfaID, goster, ustMenuID, menuIcon, menuKategoriID, menuBaslik, menuSiraNo, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimKonum, menuKonum, galeriKonum, durum
                            sayfaService.sayfaGuncelle(this.sayfaForm.sayfaID, ustSayfaID, goster, ustMenuID, menuIcon, menuKategoriID,menuBaslik , menuSiraNo, this.sayfaForm.baslik, this.sayfaForm.altBaslik, this.sayfaForm.icerik, sunucudanEklenenResimler, this.sayfaForm.resim.length > 0 ? this.sayfaForm.resim : "" , this.sayfaForm.resimKonum, this.sayfaForm.menuKonum, this.sayfaForm.galeriKonum, this.sayfaForm.durum, this.sayfaForm.seoBaslik, this.sayfaForm.seoIcerik, this.sayfaForm.link).then((response) => {
                                if (response.status == 200) {
                                    this.resetForm(formName);
                                    localStorage.setItem("userDataBGSurec", response.token)
                                    EventBus.$emit("sayfaList",true)
                                    functions.sayfaKapat('sayfaguncelle', this)
                                }
                                this.loading = false;
                                notification.Status('success', this, response.msg);
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            });
                        })
                    } else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.loading = false
                        }
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.sayfaForm.sayfaTuru = '0';
                this.visibleUstSayfa = false;
                this.sayfaForm.link = null;
                let tabs = this.$store.getters.getTabList;
                if (tabs.length === 1) {
                    $(".horizontal-nav.pos-top").removeClass("shadowBox");
                }
                var obj = tabs.find(data => data.name === "sayfaguncelle");
                if (obj !== undefined) {
                    tabs.forEach((tab, index) => {
                        if (tab.name === "sayfaguncelle") {
                            this.$store.commit('changeTabListRemove', index);
                            if (tabs[index]) {
                                this.$store.commit('changeActiveTabs', tabs[index].name);
                            } else {
                                this.$store.commit('changeActiveTabs', tabs[index - 1].name);
                            }
                        }
                    });
                }
            },
        },
    }
</script>
